import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";

import { logoutUser } from "../../redux/actions/auth/auth";
import EditUser from '../user/EditUser';
import NotificationList from "../notifications/NotificationList";
import MessageList from "../messages/MessageList";
import ToggleSpace from "./ToggleSpace";
import PmsProgressSection from './PmsProgressSection';
import PropertyMenu from "../account/PropertyMenu";
import SearchBar from './SearchBar';

import IMAGES from '../../constants/images';
import { initials } from '../utils/wordUtils';
import { stepCount } from '../../redux/selectors/space/stepCount';
import { setOnboarding } from '../../redux/actions/landing/uiConfig';
import NewReservation from '../reservations/new-reservation/NewReservation';
import { resetError } from '../../redux/actions/reservation/editReservation';
import { setCurrentSpace, setCurrentSpaceType, setMobileSidebarOpenValue, setUpgradeAccount } from '../../redux/actions/dashboard/dashboard';

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Badge from "@material-ui/core/Badge";
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import AnnounceKit from 'announcekit-react';
import { getReservationDetails, resetReservationDetails } from "../../redux/actions/beds/beds";
import { resetReservationManualOptions } from "../../redux/actions/rooms/rooms";
import Reservation from "../reservations/Reservation";

const useStyles = makeStyles((theme) => ({
  header: {
    background: 'white',
    boxShadow: 'none',
    color: '#484848',
    padding: "0px !important",
    transition: "all 0.15s linear",
    // width: `calc(100% - 60px)`,
    zIndex: "997",
    marginTop: '70px',
    [theme.breakpoints.up('xs')]: {
      // width: `calc(100% - 80px)`,
      marginLeft: 60,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "110px"
    }
  },
  toolbar: {
    alignItems: "center",
    display: "flex",
    height: "70px",
    margin: "0 auto",
    padding: "0px !important",
    paddingRight: "20px",
    paddingLeft: "20px",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down('xs')]: {
      height: "50px",
    },
  },
  activeHeader: {
    boxShadow: "0 0 10px rgba(0,0,0,0.4)"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: "space-between",
    },
  },
  headerLeft: {
    alignItems: 'center',
    color: '#1d1d1d',
    display: 'flex',
    marginLeft: 10,
    width: '60%',
  },
  headerRight: {
    alignItems: 'center',
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: 'flex-end',
    width: '40%',
    zIndex: 9999,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  logo: {
    height: 40,
    width: 40,
    '& img': {
      height: 25,
      width: "auto",
    },
    marginRight: 5,
    verticalAlign: "middle",
    display: "table-cell"
  },
  status: {
    alignItems: 'center',
    background: '#BB5533',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    height: 'calc(100% - 10px)',
    marginRight: 10,
    maxWidth: 250,
    padding: 5,
    [theme.breakpoints.up('lg')]: {
      marginRight: 20
    },
  },
  statusContainer: {
    padding: '0px 10px',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 0px)'
  },
  mobileStatus: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },
  statusBar: {
    backgroundColor: '#E6BBB3',
    borderRadius: 5,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#FFFFFF',
      borderRadius: 5
    }
  },
  statusText: {
    color: '#FFFFFF',
    fontSize: '1.1rem',
    fontWeight: 600,
    marginBottom: 5,
  },
  headerIconContainer: {
    marginRight: 10,
    padding: 8,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 20,
      padding: 0,
      '& span': {
        height: '100%',
      }
    }
  },
  headerIcon: {
    cursor: "pointer",
    height: 25,
    width: 25,
    [theme.breakpoints.down('xs')]: {
      height: 30,
      width: 30,
    },
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItem: {
    color: "#666666",
    fontSize: 14,
    fontWeight: 500,
    minWidth: 200
  },
  loadMore: {
    display: "flex",
    justifyContent: "center"
  },
  avatar: {
    backgroundColor: theme.palette.grey[300],
    border: "0px solid #e2e2e2",
    color: '#ffffff',
    fontSize: '1.5rem',
    height: 40,
    lineHeight: 2,
    textAlign: 'center',
    width: 40,
    [theme.breakpoints.down('sm')]: {
      height: 25,
      width: 25
    },
    [theme.breakpoints.down('xs')]: {
      height: 30,
      width: 30
    }
  },
  customBadge: {
    backgroundColor: "#BB5533",
    color: "white"
  },
  listOptions: {
    color: '#666666',
    cursor: 'pointer',
    fontSize: 8,
    marginLeft: 10,
    padding: '4px'
  },
  moreIcon: {
    fontSize: '1.8rem'
  },
  announcement: {
    alignItems: 'center',
    color: '#484848',
    display: 'flex',
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
    }
  },
  what: {
    marginRight: 5
  },
  menuButton: {
    marginRight: 5,
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  newBooking: {
    alignItems: 'center',
    color: '#FFFFFF',
    background: '#715497',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 600,
    fontSize: '1.2rem',
    justifyContent: 'center',
    height: 40,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    marginLeft: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 30,
      paddingLeft: 0,
      paddingRight: 0,
      marginRight: 15,
    }
  },
  addIcon: {
    color: '#FFFFFF'
  },
  community: {
    color: '#63A655',
    background: '#FFFFFF',
    border: '1px solid #63A655',
    borderRadius: 5,
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontWeight: 600,
    margin: '0px 20px 0 10px',
    padding: '8px 15px',
    '& a': {
      color: '#63A655',
    },
  },
  hostelExists: {
    height: 60,
    width: '100%',
    position: 'relative',
    backgroundColor: '#f2f2f2'
  },
  loadingState: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 999
  },
  loader: {
      textAlign: 'center',
      margin: 'auto',
      position: 'absolute',
      left: 0,
      border: 0,
      right: 0,
      top: '50%',
  },
}));


const Header = (props) => {
  const classes = useStyles();
  const { currentSpaceID, currentSpace, currentUser, stepCount, t, isLoading, errors } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [notAnchorEl, setNotAnchorEl] = React.useState(null);
  const [msgAnchorEl, setMsgAnchorEl] = React.useState(null);
  const [editUser, setEditUser] = React.useState(false);
  const [openSpaceModal, setOpenSpaceModal] = React.useState(false);
  const [newReservation, setNewReservation] = React.useState(false);
  const [reservationModal, setReservationModal] = React.useState(false);

  const history = useHistory();

  const handleCloseError = () =>{
    props.dispatch(resetError('SEARCH_RESERVATIONS'));
  }

  React.useEffect(() => {
    if (props.cancelSuccess) {
      handleCloseReservation();
    }
    // On create reservation
    if (props.createReservationSuccess) {
      //if reservation then call the view reservation api to show in the modal
      if(props.createdReservationID){
        handleViewReservation(props.createdReservationID);
      }
      handleCloseNewReservation();
    }
  }, [props.cancelSuccess, props.createReservationSuccess]);

  const handleMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleNotifications = event => {
    setNotAnchorEl(event.currentTarget);
  };

  const handleMessage = event => {
    setMsgAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleNotClose = () => {
    setNotAnchorEl(null);
  }

  const handleMsgClose = () => {
    setMsgAnchorEl(null);
  }

  const handleOnboardingClick = () => {
    props.dispatch(setOnboarding(true));
  }

  const handleLogout = () => {
    setMenuAnchorEl(null);
    props.dispatch(logoutUser());
  }

  const closeEditUser = () => {
    setEditUser(false);
  }

  const handleCloseNewReservation = () => {
    props.dispatch(resetError('ADD_RESERVATION'));
    setNewReservation(false);
  }

  const handleViewReservation = (resID) => {
    props.dispatch(getReservationDetails(resID));
    setReservationModal(true);
  }

  const handleCloseReservation = () => {
    props.dispatch(resetError('CANCEL_RESERVATION'));
    props.dispatch(resetReservationDetails());
		props.dispatch(resetReservationManualOptions())
    setReservationModal(false);
  }


  // Space Modal Toggle Functions
  // const handleClickOpen = () => {
  //   if (props.auth.user.space.length !== 0) {
  //     setOpenSpaceModal(true);
  //   }
  // };

  const handleClose = () => {
    setOpenSpaceModal(false);
  };

  const handleDrawerToggle = () => {
    props.dispatch(setMobileSidebarOpenValue(!props.mobileSidebarOpen))
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.header}
        data-intercom-target={'Header'}
      >

        {/*
        <Hidden xsDown>
          <PmsProgressSection />
        </Hidden> */}

        <Toolbar
          className={classes.toolbar}
        >

          <div className={classes.headerContainer}>

            <div className={classes.headerLeft}>
              <Hidden smUp>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <PropertyMenu />
              <Hidden xsDown>
                <SearchBar />
              </Hidden>
            </div>

            <div className={classes.headerRight}>

              {/* ------------------------------------------------------------------------ */}
              {/* ONBOARDING PROGRESS BAR */}
              {/* ------------------------------------------------------------------------ */}
              {/* {(currentSpace && (currentSpace.onboardingStatus ==='trial' || !currentSpace.onboardingStatus)) &&
                <Hidden xsDown>
                  <div className={classes.status} onClick={handleOnboardingClick}>
                    <div className={classes.statusContainer}>
                      <div className={classes.statusText}>{t('navbar.freePMS')}</div>
                      <LinearProgress className={classes.statusBar} variant="determinate" value={(stepCount/4) * 100} />
                    </div>
                  </div>
                </Hidden>
              } */}


              {/* ------------------------------------------------------------------------ */}
              {/* COMMUNITY */}
              {/* ------------------------------------------------------------------------ */}
              {/* <Hidden xsDown>
                <div className={classes.community}>
                  {(currentSpaceID !== 'community' && currentSpace) &&
                    <span>
                      <a style={{display: "table-cell"}} href="https://community.counter.app" target = "_blank" rel = "noopener noreferrer">
                        {t('navbar.community')}
                      </a>
                    </span>
                  }
                </div>
              </Hidden> */}

              {/* ------------------------------------------------------------------------ */}
              {/* WHATS NEW */}
              {/* ------------------------------------------------------------------------ */}
              <Hidden xsDown>
                <div className={classes.announcement}>
                  {/* <AnnounceKit widget="https://announcekit.app/widgets/v2/3ADQ6k">
                    <span className={classes.what}>What's new</span>
                  </AnnounceKit> */}
                </div>
              </Hidden>


              {/* ------------------------------------------------------------------------ */}
              {/* DISPLAY NOTIFICATIONS */}
              {/* ------------------------------------------------------------------------ */}
              <Hidden xsDown>
                <IconButton
                  aria-owns={Boolean(notAnchorEl) ? 'not-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={handleNotifications}
                  color="inherit"
                  className={classes.headerIconContainer}
                  style={{ backgroundColor: Boolean(notAnchorEl) ? "rgba(0, 0, 0, 0.08)" : "" }}
                >
                  <Badge
                    overlap="rectangular"
                    badgeContent={
                      props.notifications &&
                      Object.keys(props.notifications).filter(key => !props.notifications[key].status).length
                    }
                    classes={{ badge: classes.customBadge }}
                  >
                    <img className={classes.headerIcon} src={IMAGES.ICONS.notification} />
                  </Badge>
                </IconButton>
              </Hidden>

              <NotificationList
                notAnchorEl={notAnchorEl}
                handleNotClose={handleNotClose}
              />

              {/* ------------------------------------------------------------------------ */}
              {/* DISPLAY MESSAGES */}
              {/* ------------------------------------------------------------------------ */}
              <Hidden xsDown>
                <IconButton
                  aria-owns={Boolean(msgAnchorEl) ? 'msg-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={handleMessage}
                  color="inherit"
                  className={classes.headerIconContainer}
                  style={{ backgroundColor: Boolean(msgAnchorEl) ? "rgba(0, 0, 0, 0.08)" : "" }}
                >
                  <Badge
                    overlap="rectangular"
                    badgeContent={
                      props.messages &&
                      Object.keys(props.messages).filter(key => props.messages[key].unread).length
                    }
                    classes={{ badge: classes.customBadge }}
                  >
                    <img className={classes.headerIcon} src={IMAGES.ICONS.message} />
                  </Badge>
                </IconButton>
              </Hidden>

              <MessageList msgAnchorEl={msgAnchorEl} handleMsgClose={handleMsgClose} />

              {/* ------------------------------------------------------------------------ */}
              {/* NEW RESERVATION */}
              {/* ------------------------------------------------------------------------ */}
              {/* {(currentSpace && (!currentSpace.onboardingStatus || (currentSpace.onboardingStatus !=='plus' && currentSpace.onboardingStatus !=='pro'))) &&  */}
              <Hidden smDown>
                <div
                  className={classes.newBooking}
                  onClick={() => setNewReservation(true)}
                >
                  New Booking
                </div>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.newBooking} onClick={() => setNewReservation(true)}>
                  <AddIcon className={classes.addIcon} />
                </div>
              </Hidden>

              {/* ------------------------------------------------------------------------ */}
              {/* USER PROFILE MENU */}
              {/* ------------------------------------------------------------------------ */}
              <IconButton
                aria-owns={Boolean(menuAnchorEl) ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                className={classes.headerIconContainer}
                style={{ backgroundColor: Boolean(menuAnchorEl) ? "rgba(0, 0, 0, 0.08)" : "" }}
              >
                {props.auth.user.avatar &&
                  <Avatar alt={initials(props.auth.user.name)} src={props.auth.user.avatar} className={classes.avatar} />
                }
                {!props.auth.user.avatar &&
                  <Avatar alt={initials(props.auth.user.name)} className={classes.avatar} >
                    {initials(props.auth.user.name)}
                  </Avatar>
                }
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
                className={classes.menuList}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem className={classes.menuItem} onClick={() => {
                  setEditUser(true);
                  setMenuAnchorEl(null);
                }
                }>{t('navbar.editProfile')}</MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleLogout}>{t('navbar.logOut')}</MenuItem>
              </Menu>

              {editUser &&
                <EditUser open={editUser} closeEditUser={closeEditUser} />
              }

            </div>

          </div>
        </Toolbar>


        {/* <Hidden smUp>
          <PmsProgressSection />
        </Hidden> */}

        <Hidden smUp>
          <SearchBar />
        </Hidden>

        <ToggleSpace open={openSpaceModal} onClose={handleClose} />

        {/* NEW RESERVATION */}
        {newReservation &&
          <NewReservation
            open={newReservation}
            handleCloseNewReservation={handleCloseNewReservation}
            handleViewReservation={(res) => handleViewReservation(res)}
            calendarData={{
              startDate: props?.calendarDates?.startDate?.format?.("YYYY-MM-DD"),
              endDate: props?.calendarDates?.endDate?.format?.("YYYY-MM-DD")
            }}
          />
        }

        {reservationModal &&
          <Reservation
            open={reservationModal}
            handleCloseReservation={handleCloseReservation}
          />
        }

      </AppBar>

      {/* ------------- Snackbar error messages -------------- */}

      {errors && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          onClose={handleCloseError}
          autoHideDuration={6000}
          ContentProps={{
          'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{errors}</span>}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleCloseError()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}

      {/*----------------PROGRESS LOADER--------------------- */}
      {isLoading && (
          <div className={classes.loadingState}>
              <CircularProgress className={classes.loader} />
          </div>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  currentSpaceID: state.dashboard.currentSpace,
  currentSpaceType: state.dashboard.currentSpaceType,
  mobileSidebarOpen: state.dashboard.mobileSidebarOpen,
  currentSpace: state.spaces[state.dashboard.currentSpace],
  notifications: state.notifications,
  spaces: state.spaces,
  messages: state.messages,
  currentUser: state.auth.user,
  stepCount: stepCount(state.spaces, state.dashboard.currentSpace, state.dashboard.currentSpaceType, state.auth.user),

  calendarDates: state?.userUIsettings?.[state?.spaces?.[state?.dashboard?.currentSpace]?._id]?.dates || {},
  createReservationSuccess: state.success.ADD_RESERVATION,
  createdReservationID: state?.reservationDetails?.reservation?._id,
  createdReservationStatus: state?.reservationDetails?.reservation?.status,
  cancelSuccess: state.success.CANCEL_RESERVATION,
  isLoading: state.loading.GET_PORTAL_SESSION,
  errors: state.errors.SEARCH_RESERVATIONS
});

export default withTranslation()(connect(mapStateToProps)(withRouter(Header)));